@charset "utf-8";

/* ---------------------- FONT ------------------------- */
@font-face {
	font-family: 'Pretendard';
	font-weight: 400;
    src: url(../font/Pretendard/Pretendard-Medium.woff) format('woff'), 
        url(../font/Pretendard/Pretendard-Medium.woff2) format('woff2'),
        url(../font/Pretendard/Pretendard-Medium.ttf) format('truetype');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 500;
    src: url(../font/Pretendard/Pretendard-Regular.woff) format('woff'), 
        url(../font/Pretendard/Pretendard-Regular.woff2) format('woff2'),
        url(../font/Pretendard/Pretendard-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 600;
    src: url(../font/Pretendard/Pretendard-SemiBold.woff) format('woff'), 
        url(../font/Pretendard/Pretendard-SemiBold.woff2) format('woff2'),
        url(../font/Pretendard/Pretendard-SemiBold.ttf) format('truetype');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 700;
    src: url(../font/Pretendard/Pretendard-Bold.woff) format('woff'), 
        url(../font/Pretendard/Pretendard-Bold.woff2) format('woff2'),
        url(../font/Pretendard/Pretendard-Bold.ttf) format('truetype');
}


/* ---------------------- LAYOUT ------------------------- */
body {
    width: 390px;
    height: 670px;
    margin:0 auto;
    overflow: hidden;
}

.container {
    box-sizing: border-box;
}

.text_center {
    text-align: center;
}

.left_content {
    display: flex;
    justify-content:left;
}

.center_content {
    display: flex;
    justify-content:center;
}

.right_content {
    display: flex;
    justify-content:right;
}

.bottom_content {
    display: flex;
    align-items:flex-end;
}

.hidden {
    visibility: hidden;
}

.none {
    display: none;
}

.pointer {
    cursor: pointer;
}

.fadein {
    animation: fadeIn 3s;
    -webkit-animation: fadeIn 3s;
    -moz-animation: fadeIn 3s;
    -o-animation: fadeIn 3s;
    -ms-animation: fadeIn 3s;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-o-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@media screen and (min-width:1024px) {
    body {
        margin:0 auto;
    }
}

@media screen and (min-width:481px) and (max-width:1024px) {
    body {
        width: 100%;
        height: auto;
        margin:0 auto;
        overflow: auto;
    }
}

@media screen and (max-width:480px) {
    body {
        max-width:480px;
        width: 100%;
        height: calc(var(--ih, 100vh));
        margin:0 auto;
        overflow: hidden;
    }
}