@import url('reset.css');

.admin .csv_link {
    font-family: 'SUIT';
    font-weight: 500;
    /* font-size: 1px; */
    position: absolute;
    top:20px;
    right:3vw;
}

.admin .csv_link button {
    /* width:12vw; */
    padding: 6px;
    /* height: 20px; */
    background: #168;
    border-radius: 5px;
    font-family: 'SUIT';
    font-weight: 500;
    font-size: 15px;
    color: #fff;
}

.admin h1 {
    font-family: 'SUIT';
    font-weight: 500;
    font-size: 40px;
    position: absolute;
    top:10px;
    left:40px;
}

.admin h2 {
    font-family: 'SUIT';
    font-weight: 500;
    font-size: 30px;
    position: absolute;   
}

.admin .today_num {
    left:350px;
    top:20px;
}

.admin .total_num {
    left:600px;
    top:20px;
}

.admin table {
    border-collapse: collapse;
    border-top: 3px solid #168;
    width: 95vw;
    position: absolute;
    top:70px;
    left:2.5vw;
    font-family: 'SUIT';
    font-weight: 500;
    font-size: 14px;
} 
.admin table th {
    color: #168;
    background: #f0f6f9;
}
.admin table td {
    width: 7.5vw;
}
.admin table th, table td {
    padding: 10px;
    border: 1px solid #ddd;
}
.admin table th:first-child, table td:first-child {
    border-left: 0;
}
.admin table th:last-child, table td:last-child {
    border-right: 0;
}
.admin td input {
    /* height: 60px; */
    width: 6.8vw;
    padding-left: 5px;
    border: solid 2px #AAAAAA;
    border-radius: 5px;
    font-family: 'SUIT';
    font-weight: 500;
    font-size: 14px;
    box-sizing: border-box;
    color:black;
}

.admin td select {
    width: 5vw;
    padding-left: 10px;
    border: solid 2px #AAAAAA;
    border-radius: 5px;
    font-family: 'SUIT';
    font-weight: 500;
    font-size: 14px;
    box-sizing: border-box;
    color:black;
}

.admin tbody tr:hover td {
    background: white;
    color: black;
}

.admin .btn_update {
    width:3.5vw;
    margin-top: 8px;
    border: solid 1px #168;
    margin-left: 1px;
    background: #fff;
    border-radius: 5px;
    font-family: 'SUIT';
    font-weight: 500;
    font-size: 14px;
    color: #168;
}