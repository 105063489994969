@import url('reset.css');
@import url('common.css');

/* PC 화면 (px로 고정) */
@media screen and (min-width:1024px) {
    .qr {
        width: 390px;
        height: 670px;
        background-color: black;
        box-sizing: border-box;
    }

    .qr .card_area {
        position: absolute;
        width: 390px;
        height: 670px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .qr .card_area .card_template {
        position: absolute;
        display: block;
        width: 100%;
    }

    .first {
        width: 390px;
        height: 670px;
        background: url(../img/main/bg1.png) no-repeat center center/cover;
        box-sizing: border-box;
    }
    
    header .header_text_logo {
        width: 253px;
        height: 100px;
        margin-top: 57px;
    }

    .first .img_haus {
        width: 233px;
        height: 243px;
        margin-top: 68px;
        margin-bottom: 99px;
    }
    
    .first .first_button {
        width: 163px;
    }

    .start {
        width: 390px;
        height: 670px;
        background: url(../img/main/bg2.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .start .img_description {
        width: 200px;
        margin-top: 53.3px;
        margin-left: 60.3px;
        margin-bottom: 260.3px;
    }

    .start .start_button {
        width: 264px;
    }

    .age {
        width: 390px;
        height: 670px;
        background: url(../img/age/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .age .age_img_haus {
        position: absolute;
        width: 281px;
        margin-top: 140px;
        z-index: 1;
    }

    .age .age_text_question {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: 17.5px;
        line-height: 21px;
        margin-top: 281px;
        margin-bottom: 31px;
        z-index: 2;
    }

    .age .radio_content .radio_box {
        display: inline-block;
        font-family: 'Pretendard', sans-serif;
        font-weight: 700;
        font-size: 17.46px;
        z-index: 2;
        margin-bottom: 213px;
    }

    .age .radio_content .radio_box .age_img_check_box_yes {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-bottom: -2px;
    }

    .age .radio_content .radio_box .age_img_checked_yes {
        display: inline-block;
        width: 10px;
        height: 8px;
        margin-bottom: 1px;
        margin-left: -12.5px;
        margin-right: 13px;
    }

    .age .radio_content .radio_box .age_img_check_box_no {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-left: 28px;
        margin-bottom: -2px;
    }

    .age .radio_content .radio_box .age_img_checked_no {
        display: inline-block;
        width: 10px;
        height: 8px;
        margin-bottom: 1px;
        margin-left: -12.5px;
        margin-right: 13px;
    }

    .age .age_img_warning {
        width: 223px;
    }

    .info {
        width: 390px;
        height: 670px;
        background: url(../img/info/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .info .name_box {
        box-sizing: border-box;
        height: 110px;
        padding-top: 85px;
    }

    .info .name_box .label_name {
        box-sizing: border-box;
        height: 18px;
        margin-top: 5.6px;
    }

    .info .name_box .label_name_ko {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: 14px;
        margin-right: 4.3px;
    }

    .info .name_box .label_name_en {
        font-family: 'Pretendard', sans-serif;
        font-weight: 500;
        font-size: 14px;
        margin-right: 33px;
    }

    .info .name_box .input_name {
        display: block;
        font-family: 'Pretendard', sans-serif;
        font-weight: 400;
        font-size: 16.7px;
        width: 207px;
        text-align: center;
        border: 0;
        margin: 0 0 5px 0;
        padding: 0;
        background: transparent;
        outline: none;
    }

    .info .name_box .info_img_name_line {
        display: block;
        width: 207px;
        height: 1.3px;
    }
    
    .info .mobile_box {    
        box-sizing: border-box;    
        height: 38px;
        margin-top: 37px;
    }

    .info .mobile_box .label_mobile {    
        box-sizing: border-box;    
        margin-top: 6.3px;
    }

    .info .mobile_box .label_mobile_ko {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: 14px;
        margin-right: 5.3px;
    }

    .info .mobile_box .label_mobile_en {
        font-family: 'Pretendard', sans-serif;
        font-weight: 500;
        font-size: 14px;
        margin-right: 9.3px;
    }

    .info .mobile_box .input_mobile {
        display: block;
        font-family: 'Pretendard', sans-serif;
        font-weight: 400;
        font-size: 16.7px;
        width: 207px;
        text-align: center;
        border: 0;
        margin: 0 0 4px 0;
        padding: 0;
        background:transparent;
        outline: none;
    }

    .info .mobile_box .info_img_mobile_line {
        display: block;
        width: 207px;
        height: 1.3px;
    }

    .info .mobile_box .info_img_mobile_notice_text {
        width: 200.3px;
        margin-top: 1px;
    }

    .info .gender_box {
        box-sizing: border-box;
        height: 16.7px;
        margin-top: 21.42px;
    }

    .info .gender_box .label_gender {
        box-sizing: border-box;
        height: 18px;
        margin-top:1.1px;
    }

    .info .gender_box .label_gender_ko {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: 14px;
        margin-right: 4.3px;
    }

    .info .gender_box .label_gender_en {
        font-family: 'Pretendard', sans-serif;
        font-weight: 500;
        font-size: 14px;
        margin-right: 18.3px;
    }

    .info .gender_box .gender_radio .info_img_check_box_male {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-bottom: -3px;
    }

    .info .gender_box .gender_radio .info_img_checked_male {
        display: inline-block;
        width: 10px;
        height: 8px;
        margin-left: -12.5px;
        margin-right: 7.3px;
    }

    .info .gender_box .gender_radio .info_text_male_ko {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: 14px;
        margin-right: 5.3px;
    }

    .info .gender_box .gender_radio .info_text_male_en {
        font-family: 'Pretendard', sans-serif;
        font-weight: 500;
        font-size: 14px;
        margin-right: 44px;
    }

    .info .gender_box .gender_radio .info_img_check_box_female {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-bottom: -3px;
    }

    .info .gender_box .gender_radio .info_img_checked_female {
        display: inline-block;
        width: 10px;
        height: 8px;
        margin-bottom: 0px;
        margin-left: -12.5px;
        margin-right: 7.3px;
    }

    .info .gender_box .gender_radio .info_text_female_ko {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: 14px;
        margin-right: 5.3px;
    }

    .info .gender_box .gender_radio .info_text_female_en {
        font-family: 'Pretendard', sans-serif;
        font-weight: 500;
        font-size: 14px;
    }

    .info .age_box {
        box-sizing: border-box;
        height: 16.7px;
        margin-top: 44.6px;
        margin-bottom: 52.2px;
    }

    .info .label_age {
        box-sizing: border-box;
        height: 16.7px;
    }

    .info .age_box .label_age_ko {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: 14px;
        margin-right: 5.3px;
    }

    .info .age_box .label_age_en {
        font-family: 'Pretendard', sans-serif;
        font-weight: 500;
        font-size: 14px;
        margin-right: 32.7px;
    }

    .info .age_box .info_img_check_box_age {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-bottom: -2px;
    }

    .info .age_box .age_radio {
        box-sizing: border-box;
        height: 16.7px;
        margin-right: 7.6px;
    }

    .info .age_box .info_img_checked_20, 
    .info .age_box .info_img_checked_30, 
    .info .age_box .info_img_checked_40, 
    .info .age_box .info_img_checked_50, 
    .info .age_box .info_img_checked_60 {
        display: inline-block;
        width: 10px;
        height: 8px;
        margin-left: -12.5px;
        margin-right: 6px;
        margin-bottom: 1px;
    }

    .info .age_box .info_text_age {
        vertical-align: top;
        font-family: 'Pretendard', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: auto;
    }

    .info .agree_box {
        box-sizing: border-box;
        vertical-align: top;
        height: 15px;
        margin-bottom: 10.6px;
    }

    .info .agree_box .label_agree {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: 12px;
        margin-right: 8px;
    }

    .info .agree_box .agree_checkbox_box {
        margin-right: 33px;
    }

    .info .agree_box .info_img_agree_checkbox {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-bottom: -4px;
    }

    .info .agree_box .info_img_agree_checked {
        display: inline-block;
        width: 10px;
        height: 8px;
        margin-left: -12.5px;
        margin-bottom: -1px;
    }

    .info .agree_box .label_agree_see {
        font-family: 'Pretendard', sans-serif;
        font-weight: 500;
        font-size: 10.5px;
        margin-bottom: 1.4px;
    }

    .info .warning_box .warning_text .info_img_warning_text {
        width: 305.9px;
        height: 9.76px;
    }

    .info .info_img_register {
        width: 263.7px;
        height: 53.8px;
        margin-top: 197.6px;
    }

    .info_img_popup {
        position: absolute;
        top: 70px;
        width: 321.6px;
        height: 530px;
        z-index: 3;
    }

    .info_img_popup_close {
        position: absolute;
        top: 553px;
        width: 77.3px;
        height: 20.3px;
        z-index: 4;
    }

    .info_img_popup_privacy_use_agree_checked {
        position: absolute;
        top: 115.4px;
        margin-left: 161.22px;
        width: 5.2px;
        height: 3.8px;
        z-index: 4;
    }

    .info_img_popup_privacy_use_agree {
        position: absolute;
        top: 113.4px;
        margin-left: 159.6px;
        width: 97px;
        height: 8.33px;
        z-index: 4;
    }

    .info_img_popup_privacy_use_disagree_checked {
        position: absolute;
        top: 115.4px;
        margin-left: 281px;
        width: 5.2px;
        height: 3.8px;
        z-index: 4;
    }

    .info_img_popup_privacy_use_disagree {
        position: absolute;
        top: 113.4px;
        margin-left: 279.4px;
        width: 48.39px;
        height: 8.33px;
        z-index: 4;
    }

    .info_img_popup_marketing_agree_checked {
        position: absolute;
        top: 265px;
        margin-left: 161.1px;
        width: 5.2px;
        height: 3.8px;
        z-index: 4;
    }

    .info_img_popup_marketing_agree {
        position: absolute;
        top: 263px;
        margin-left: 159.6px;
        width: 95.67px;
        height: 8.33px;
        z-index: 4;
    }

    .info_img_popup_marketing_disagree_checked {
        position: absolute;
        top: 265px;
        margin-left: 277.5px;
        width: 5.2px;
        height: 3.8px;
        z-index: 4;
    }

    .info_img_popup_marketing_disagree {
        position: absolute;
        top: 263px;
        margin-left: 276px;
        width: 48.39px;
        height: 8.33px;
        z-index: 4;
    }

    .info_img_popup_privacy_offer_agree_checked {
        position: absolute;
        top: 383px;
        margin-left: 161.1px;
        width: 5.2px;
        height: 3.8px;
        z-index: 4;
    }

    .info_img_popup_privacy_offer_agree {
        position: absolute;
        top: 381.2px;
        margin-left: 159.6px;
        width: 97px;
        height: 8.33px;
        z-index: 4;
    }




    .info_img_popup_privacy_offer_disagree_checked {
        position: absolute;
        top: 383px;
        margin-left: 277.6px;
        width: 5.2px;
        height: 3.8px;
        z-index: 4;
    }

    .info_img_popup_privacy_offer_disagree {
        position: absolute;
        top: 381.2px;
        margin-left: 276px;
        width: 48.39px;
        height: 8.33px;
        z-index: 4;
    }

    .info_img_popup_privacy_policy_link {
        position: absolute;
        top: 495.33px;
        margin-left: 60.67px;
        width: 85.33px;
        height: 9px;
        z-index: 4;
    }

    .loading {
        width: 390px;
        height: 670px;
        background: url(../img/loading/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .loading .loading_img_haus {
        position: absolute;
        width: 74.33px;
        margin-top: 93.67px;
        z-index: 1;
    }

    .loading .loading_text_line1 {
        margin-top: 215px;
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: 26.67px;
    }

    .loading .loading_line2 {
        box-sizing: border-box;
        height: 18.33px;
        margin-top: 13.1px;
        margin-bottom: 64.78px;
    }

    .loading .loading_name_box {
        box-sizing: border-box;
        width: 88px;
        height: 33.33px;
    }

    .loading .loading_text_name {
        font-family: 'Pretendard', sans-serif;
        font-weight: 400;
        font-size: 24px;
    }

    .loading .loading_text_line2 {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: 15.14px;
        margin-top: 5px;
    }

    .loading .loading_img_line {
        display: block;
        margin-top: 3px;
        width: 88px;
        height: 1.3px;
    }

    .loading .loading_img_capsule {
        display: block;
        width: 14.77px;
        height: 15.42px;
        margin-bottom: 60.91px;
    }

    .question header .question_back {
        padding-top: 26.67px;
        padding-left: 39px;
        margin-bottom: 120.67px;
    }

    .question header .question_back .question_back_btn {
        display: inline-block;
        width: 76.33px;
        height: 20px;
    }

    .question main .question_text_question {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: 30px;
        text-align: center;
        margin-bottom: 52px;
        line-height: 1.2;
    }

    .question main .question_radio .question_content {
        height: 22px;
        margin-bottom: 7.76px;
        box-sizing: border-box;
    }

    .question main .question_radio .question_content_text {
        font-family: 'Pretendard', sans-serif;
        font-weight: 400;
        font-size: 18.33px;
        margin-left: 10px;
    }

    .question main .question_radio .question_img_checked {
        width: 29.33px;
        height: 18px;
    }

    .question1 {
        width: 390px;
        height: 670px;
        background: url(../img/question/1/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .question1 .question_img_line {
        display: block;
        height: 1.3px;
        margin-bottom: 51px;
        width: 167.67px;
    }

    .question2 {
        width: 390px;
        height: 670px;
        background: url(../img/question/2/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .question2 .question_img_line {
        display: block;
        height: 1.3px;
        margin-bottom: 51px;
        width: 285.67px;
    }

    .question3 {
        width: 390px;
        height: 670px;
        background: url(../img/question/3/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .question3 .question_img_line {
        display: block;
        height: 1.3px;
        margin-bottom: 51px;
        width: 157px;
    }

    .question4 {
        width: 390px;
        height: 670px;
        background: url(../img/question/4/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .question4 .question_img_line {
        display: block;
        height: 1.3px;
        margin-bottom: 51px;
        width: 220px;
    }

    .question5 {
        width: 390px;
        height: 670px;
        background: url(../img/question/5/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .question5 .question_img_line {
        display: block;
        height: 1.3px;
        margin-bottom: 51px;
        width: 251px;
    }

    .question6 {
        width: 390px;
        height: 670px;
        background: url(../img/question/6/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .question6 .question_img_line {
        display: block;
        height: 1.3px;
        margin-bottom: 51px;
        width: 167px;
    }

    .question7 {
        width: 390px;
        height: 670px;
        background: url(../img/question/7/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .question7 .question_img_line {
        display: block;
        height: 1.3px;
        margin-bottom: 51px;
        width: 203px;
    }

    .question8 {
        width: 390px;
        height: 670px;
        background: url(../img/question/8/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .question8 .question_img_line {
        display: block;
        height: 1.3px;
        margin-bottom: 51px;
        width: 211px;
    }

    .analysis {
        width: 390px;
        height: 670px;
        background: url(../img/analysis/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .analysis .analysis_img_haus {
        position: absolute;
        width: 74.33px;
        margin-top: 93.67px;
        z-index: 1;
    }

    .analysis .analysis_text_line1 {
        margin-top: 215px;
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: 26.67px;
    }

    .analysis .analysis_line2 {
        box-sizing: border-box;
        height: 18.33px;
        margin-top: 13.1px;
        margin-bottom: 64.78px;
    }

    .analysis .analysis_name_box {
        box-sizing: border-box;
        width: 88px;
        height: 33.33px;
    }

    .analysis .analysis_text_name {
        font-family: 'Pretendard', sans-serif;
        font-weight: 400;
        font-size: 24px;
    }

    .analysis .analysis_text_line2 {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: 15.14px;
        margin-top: 5px;
    }

    .analysis .analysis_img_line {
        display: block;
        margin-top: 3px;
        width: 88px;
        height: 1.3px;
    }

    .analysis .analysis_img_capsule {
        display: block;
        width: 14.77px;
        height: 15.42px;
        margin-bottom: 60.91px;
    }

    .result .result_text_box {
        height: 36px;
        box-sizing: border-box;
        margin-top: 193.33px;
    }

    .result .result_text_box .result_name_box .result_text_name {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: 24.69px;
        letter-spacing: -0.99px;
        margin-bottom: 5.5px;
    }

    .result .result_text_box .result_name_box .result_img_line {
        display: block;
        width: 90.93px;
        height: 1.14px;
    }

    .result .result_text_box .result_text_line {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: 17.64px;
        letter-spacing: -0.35px;
        margin-top: 4.4px;
    }

    .result .home_btn {
        position: fixed;
        bottom: 0;
        width: 100px;
        height: 20px;
    }
    .result .home_btn a {
        width: 100px;
        height: 100px;
    }

    .awb {
        width: 390px;
        height: 1165.33px;
        background: url(../img/result/awb.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .baw {
        width: 390px;
        height: 1129.33px;
        background: url(../img/result/baw.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .cac {
        width: 390px;
        height: 1105.67px;
        background: url(../img/result/cac.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .cia {
        width: 390px;
        height: 1187.33px;
        background: url(../img/result/cia.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .dal {
        width: 390px;
        height: 1187.33px;
        background: url(../img/result/dal.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .daa {
        width: 390px;
        height: 1105.33px;
        background: url(../img/result/daa.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .ipa {
        width: 390px;
        height: 1127.33px;
        background: url(../img/result/ipa.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .paa {
        width: 390px;
        height: 1187.33px;
        background: url(../img/result/paa.png) no-repeat center center/cover;
        box-sizing: border-box;
    }


    .pil {
        width: 390px;
        height: 1105.67px;
        background: url(../img/result/pil.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .rea {
        width: 390px;
        height: 1127.33px;
        background: url(../img/result/rea.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .sei {
        width: 390px;
        height: 1164.667px;
        background: url(../img/result/sei.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .sol {
        width: 390px;
        height: 1127.33px;
        background: url(../img/result/sol.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .spi {
        width: 390px;
        height: 1072px;
        background: url(../img/result/spi.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .sto {
        width: 390px;
        height: 1105.33px;
        background: url(../img/result/sto.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .sua {
        width: 390px;
        height: 1164.667px;
        background: url(../img/result/sua.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .whe {
        width: 390px;
        height: 1072px;
        background: url(../img/result/whe.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

}

/* 여기까지는 그냥 너비를 가득 채우는 걸로 (vw로 단위 계산)... */
@media screen and (min-width:480px) and (max-width:1024px) {
    .qr {
        width: 100vw;
        height: 100vh;
        background-color: black;
        box-sizing: border-box;
    }

    .qr .card_area {
        position: absolute;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .qr .card_area .card_template {
        position: absolute;
        display: block;
        width: 100%;
    }

    .first {
        width: 100vw;
        height: auto;
        background: url(../img/main/bg1.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    header .header_text_logo {
        width: calc(253/390*100vw);
        height: calc(100/390*100vw);
        margin-top: calc(57/390*100vw);
    }

    .first .img_haus {
        width: calc(233/390*100vw);
        height: calc(243/390*100vw);
        margin-top: calc(68/390*100vw);
        margin-bottom: calc(99/390*100vw);
    }

    .first .first_button {
        width: calc(163/390*100vw);
        margin-bottom: calc(49/390*100vw);
    }
    
    .start {
        width: 100vw;
        height: auto;
        background: url(../img/main/bg2.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .start .img_description {
        width: calc(200/390*100vw);
        margin-top: calc(53.3/390*100vw);
        margin-left: calc(60.3/390*100vw);
        margin-bottom: calc(260.3/390*100vw);
    }

    .start .start_button {
        width: calc(264/390*100vw);
        margin-bottom: calc(49/390*100vw);
    }

    .age {
        width: 100vw;
        height: auto;
        background: url(../img/age/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .age .age_img_haus {
        position: absolute;
        width: calc(281/390*100vw);
        margin-top: calc(140/390*100vw);
        z-index: 1;
    }

    .age .age_text_question {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(17.5/390*100vw);
        line-height: calc(21/390*100vw);
        margin-top: calc(281/390*100vw);
        margin-bottom: calc(31/390*100vw);
        z-index: 2;
    }

    .age .radio_content .radio_box {
        display:inline-block;
        font-family: 'Pretendard', sans-serif;
        font-weight: 700;
        font-size: calc(17.46/390*100vw);
        line-height: auto;
        z-index: 2;
        margin-bottom: calc(213/390*100vw);
    }

    .age .radio_content .radio_box .age_img_check_box_yes {
        display: inline-block;
        width: calc(15/390*100vw);
        height: calc(15/390*100vw);
        margin-bottom: calc(-2/390*100vw);
    }

    .age .radio_content .radio_box .age_img_checked_yes {
        display: inline-block;
        width: calc(10/390*100vw);
        height: calc(8/390*100vw);
        margin-bottom: calc(1/390*100vw);
        margin-left: calc(-12.5/390*100vw);
        margin-right: calc(13/390*100vw);
    }

    .age .radio_content .radio_box .age_img_check_box_no {
        display: inline-block;
        width: calc(15/390*100vw);
        height: calc(15/390*100vw);
        margin-left: calc(28/390*100vw);
        margin-bottom: calc(-2/390*100vw);
    }

    .age .radio_content .radio_box .age_img_checked_no {
        display: inline-block;
        width: calc(10/390*100vw);
        height: calc(8/390*100vw);
        margin-bottom: calc(1/390*100vw);
        margin-left: calc(-12.5/390*100vw);
        margin-right: calc(13/390*100vw);
    }

    .age .radio_content .radio_box .age_img_line {
        display:block;
        width: calc(70.74/390*100vw);
        height: calc(1.14/390*100vw);
        margin-right: calc(24/390*100vw);
        margin-bottom: calc(216.4/390*100vw);
    }

    .age .age_img_warning {
        width: calc(223/390*100vw);
        margin-bottom: calc(62.74/390*100vw);
    }

    .info {
        width: 100vw;
        height: auto;
        background: url(../img/info/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .info .name_box {
        box-sizing: border-box;
        height: calc(110/390*100vw);
        padding-top: calc(85/390*100vw);
    }

    .info .name_box .label_name {        
        box-sizing: border-box;
        height: calc(18/390*100vw);
        margin-top: calc(5.6/390*100vw);
    }

    .info .name_box .label_name_ko {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(14/390*100vw);
        letter-spacing: 0px;
        margin-right: calc(4.3/390*100vw);
    }

    .info .name_box .label_name_en {
        font-family: 'Pretendard', sans-serif;
        font-weight: 500;
        font-size: calc(14/390*100vw);
        margin-right: calc(33/390*100vw);
    }

    .info .name_box .input_name {
        display: block;
        font-family: 'Pretendard', sans-serif;
        font-weight: 400;
        font-size: calc(16.7/390*100vw);
        width: calc(207/390*100vw);
        text-align: center;
        border: 0;
        margin: 0 0 calc(5/390*100vw) 0;
        padding: 0;
        background:transparent;
        outline: none;
    }

    .info .name_box .info_img_name_line {
        display: block;
        width: calc(207/390*100vw);
        height: calc(1.3/390*100vw);
    }
    
    .info .mobile_box {    
        box-sizing: border-box;    
        height: calc(38/390*100vw);
        margin-top: calc(37/390*100vw);
    }

    .info .mobile_box .label_mobile {    
        box-sizing: border-box;    
        margin-top: calc(6.3/390*100vw);
    }

    .info .mobile_box .label_mobile_ko {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(14/390*100vw);
        margin-right: calc(5.3/390*100vw);
    }

    .info .mobile_box .label_mobile_en {
        font-family: 'Pretendard', sans-serif;
        font-weight: 500;
        font-size: calc(14/390*100vw);
        margin-right: calc(9.3/390*100vw);
    }

    .info .mobile_box .input_mobile {
        display: block;
        font-family: 'Pretendard', sans-serif;
        font-weight: 400;
        font-size: calc(16.7/390*100vw);
        width: calc(207/390*100vw);
        text-align: center;
        border: 0;
        margin: 0 0 calc(4/390*100vw) 0;
        padding: 0;
        background: transparent;
        outline: none;
    }

    .info .mobile_box .info_img_mobile_line {
        display: block;
        width: calc(207/390*100vw);
        height: calc(1.3/390*100vw);
    }

    .info .mobile_box .info_img_mobile_notice_text {
        width: calc(200.3/390*100vw);
        margin-top: calc(1/390*100vw);
    }

    .info .gender_box {
        box-sizing: border-box;
        height: calc(16.7/390*100vw);
        margin-top: calc(21.42/390*100vw);
    }

    .info .gender_box .label_gender {
        box-sizing: border-box;
        height: calc(18/390*100vw);
        margin-top: calc(1.1/390*100vw);
    }

    .info .gender_box .label_gender_ko {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(14/390*100vw);
        margin-right:calc(4.3/390*100vw);
    }

    .info .gender_box .label_gender_en {
        font-family: 'Pretendard', sans-serif;
        font-weight: 500;
        font-size: calc(14/390*100vw);
        margin-right: calc(18.3/390*100vw);
    }

    .info .gender_box .gender_radio .info_img_check_box_male {
        display: inline-block;
        width: calc(15/390*100vw);
        height: calc(15/390*100vw);
        margin-bottom: calc(-3/390*100vw);
    }

    .info .gender_box .gender_radio .info_img_checked_male {
        display: inline-block;
        width: calc(10/390*100vw);
        height: calc(8/390*100vw);
        margin-left: calc(-12.5/390*100vw);
        margin-right: calc(7.3/390*100vw);
    }

    .info .gender_box .gender_radio .info_text_male_ko {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(14/390*100vw);
        margin-right: calc(5.3/390*100vw);
    }

    .info .gender_box .gender_radio .info_text_male_en {
        font-family: 'Pretendard', sans-serif;
        font-weight: 500;
        font-size: calc(14/390*100vw);
        margin-right: calc(44/390*100vw);
    }

    .info .gender_box .gender_radio .info_img_check_box_female {
        display: inline-block;
        width: calc(15/390*100vw);
        height: calc(15/390*100vw);
        margin-bottom: calc(-3/390*100vw);
    }

    .info .gender_box .info_img_male_line {
        display: block;
        width: calc(86.64/390*100vw);
        height: calc(1.14/390*100vw);
        margin-top: calc(2.8/390*100vw);
        margin-right: calc(33.8/390*100vw);
    }

    .info .gender_box .gender_radio .info_img_checked_female {
        display: inline-block;
        width: calc(10/390*100vw);
        height: calc(8/390*100vw);
        margin-left: calc(-12.5/390*100vw);
        margin-right: calc(7.3/390*100vw);
    }

    .info .gender_box .gender_radio .info_text_female_ko {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(14/390*100vw);
        margin-right: calc(5.3/390*100vw);
    }

    .info .gender_box .gender_radio .info_text_female_en {
        font-family: 'Pretendard', sans-serif;
        font-weight: 500;
        font-size: calc(14/390*100vw);
    }

    .info .age_box {
        box-sizing: border-box;
        height: calc(16.7/390*100vw);
        margin-top: calc(44.6/390*100vw);
        margin-bottom: calc(52.2/390*100vw);
    }

    .info .label_age {
        box-sizing: border-box;
        height: calc(16.7/390*100vw);
    }

    .info .age_box .label_age_ko {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(14/390*100vw);
        margin-right: calc(5.3/390*100vw);
    }

    .info .age_box .label_age_en {
        font-family: 'Pretendard', sans-serif;
        font-weight: 500;
        font-size: calc(14/390*100vw);
        margin-right: calc(32.7/390*100vw);
    }

    .info .age_box .info_img_check_box_age {
        display: inline-block;
        width: calc(15/390*100vw);
        height: calc(15/390*100vw);
        margin-bottom: calc(-2/390*100vw);
    }

    .info .age_box .age_radio {
        box-sizing: border-box;
        height: calc(16.7/390*100vw);
        margin-right: calc(7.6/390*100vw);
    }

    .info .age_box .info_img_checked_20, 
    .info .age_box .info_img_checked_30, 
    .info .age_box .info_img_checked_40, 
    .info .age_box .info_img_checked_50, 
    .info .age_box .info_img_checked_60 {
        display: inline-block;
        width: calc(10/390*100vw);
        height: calc(8/390*100vw);
        margin-left: calc(-12.5/390*100vw);
        margin-right: calc(6/390*100vw);
        margin-bottom: calc(1/390*100vw);
    }



    .info .age_box .info_text_age {
        vertical-align: top;
        font-family: 'Pretendard', sans-serif;
        font-weight: 500;
        font-size: calc(14/390*100vw);
        line-height: auto;
    }


    .info .agree_box {
        box-sizing: border-box;
        vertical-align: top;
        height: calc(15/390*100vw);
        margin-bottom: calc(10.6/390*100vw);
    }
    
    .info .agree_box .label_agree {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(12/390*100vw);
        margin-right: calc(8/390*100vw);
    }

    .info .agree_box .agree_checkbox_box {
        margin-right: calc(33/390*100vw);
    }

    .info .agree_box .info_img_agree_checkbox {
        display: inline-block;
        width: calc(15/390*100vw);
        height: calc(15/390*100vw);
        margin-bottom: calc(-4/390*100vw);
    }

    .info .agree_box .info_img_agree_checked {
        display: inline-block;
        width: calc(10/390*100vw);
        height: calc(8/390*100vw);
        margin-left: calc(-12.5/390*100vw);
        margin-bottom: calc(-1/390*100vw);
    }
    
    .info .agree_box .label_agree_see {
        font-family: 'Pretendard', sans-serif;
        font-weight: 500;
        font-size: calc(10.5/390*100vw);
        margin-bottom: calc(1.4/390*100vw);
    }

    .info .warning_box .warning_text .info_img_warning_text {
        width: calc(305.9/390*100vw);
        height: calc(9.76/390*100vw);
    }
    
    .info .info_img_register {
        width: calc(263.7/390*100vw);
        height: calc(53.8/390*100vw);
        margin-top: calc(197.6/390*100vw);
        margin-bottom: calc(49.2/390*100vw);
    }

    .info_img_popup {
        position: absolute;
        top: calc(70/390*100vw);
        width: calc(321.6/390*100vw);
        height: calc(530/390*100vw);
        z-index: 3;
    }
    
    .info_img_popup_close {
        position: absolute;
        top: calc(553/390*100vw);
        width: calc(77.3/390*100vw);
        height: calc(20.3/390*100vw);
        z-index: 4;
    }

    .info_img_popup_privacy_use_agree_checked {
        position: absolute;
        top: calc(115.4/390*100vw);
        margin-left: calc(161.22/390*100vw);
        width: calc(5.2/390*100vw);
        height: calc(3.8/390*100vw);
        z-index: 4;
    }

    .info_img_popup_privacy_use_agree {
        position: absolute;
        top: calc(113.4/390*100vw);
        margin-left: calc(159.6/390*100vw);
        width: calc(97/390*100vw);
        height: calc(8.33/390*100vw);
        z-index: 4;
    }



    .info_img_popup_privacy_use_disagree_checked {
        position: absolute;
        top: calc(115.4/390*100vw);
        margin-left: calc(281/390*100vw);
        width: calc(5.2/390*100vw);
        height: calc(3.8/390*100vw);
        z-index: 4;
    }

    .info_img_popup_privacy_use_disagree {
        position: absolute;
        top: calc(113.4/390*100vw);
        margin-left: calc(279.4/390*100vw);
        width: calc(48.39/390*100vw);
        height: calc(8.33/390*100vw);
        z-index: 4;
    }




    .info_img_popup_marketing_agree_checked {
        position: absolute;
        top: calc(265/390*100vw);
        margin-left: calc(160.7/390*100vw);
        width: calc(5.2/390*100vw);
        height: calc(3.8/390*100vw);
        z-index: 4;
    }

    .info_img_popup_marketing_agree {
        position: absolute;
        top: calc(263/390*100vw);
        margin-left: calc(159.6/390*100vw);
        width: calc(95.67/390*100vw);
        height: calc(8.33/390*100vw);
        z-index: 4;
    }

    .info_img_popup_marketing_disagree_checked {
        position: absolute;
        top: calc(265/390*100vw);
        margin-left: calc(277.5/390*100vw);
        width: calc(5.2/390*100vw);
        height: calc(3.8/390*100vw);
        z-index: 4;
    }

    .info_img_popup_marketing_disagree {
        position: absolute;
        top: calc(263/390*100vw);
        margin-left: calc(276/390*100vw);
        width: calc(48.39/390*100vw);
        height: calc(8.33/390*100vw);
        z-index: 4;
    }

    .info_img_popup_privacy_offer_agree_checked {
        position: absolute;
        top: calc(383/390*100vw);
        margin-left: calc(161.1/390*100vw);
        width: calc(5.2/390*100vw);
        height: calc(3.8/390*100vw);
        z-index: 4;
    }

    .info_img_popup_privacy_offer_agree {
        position: absolute;
        top: calc(381.2/390*100vw);
        margin-left: calc(159.6/390*100vw);
        width: calc(97/390*100vw);
        height: calc(8.33/390*100vw);
        z-index: 4;
    }
    
    .info_img_popup_privacy_offer_disagree_checked {
        position: absolute;
        top: calc(383/390*100vw);
        margin-left: calc(277.6/390*100vw);
        width: calc(5.2/390*100vw);
        height: calc(3.8/390*100vw);
        z-index: 4;
    }

    .info_img_popup_privacy_offer_disagree {
        position: absolute;
        top: calc(381.2/390*100vw);
        margin-left: calc(276/390*100vw);
        width: calc(48.39/390*100vw);
        height: calc(8.33/390*100vw);
        z-index: 4;
    }
    

    .info_img_popup_privacy_policy_link {
        position: absolute;
        top: calc(495.33/390*100vw);
        margin-left: calc(60.67/390*100vw);
        width: calc(85.33/390*100vw);
        height: calc(9/390*100vw);
        z-index: 4;
    }

    .loading {
        width: 100vw;
        height: auto;
        background: url(../img/loading/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .loading .loading_img_haus {
        position: absolute;
        width: calc(74.33/390*100vw);
        margin-top: calc(93.67/390*100vw);
        z-index: 1;
    }

    .loading .loading_text_line1 {
        margin-top: calc(215/390*100vw);
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(26.67/390*100vw);
    }

    .loading .loading_line2 {
        box-sizing: border-box;
        height: calc(18.33/390*100vw);
        margin-top: calc(13.1/390*100vw);
        margin-bottom: calc(64.78/390*100vw);
    }

    .loading .loading_name_box {
        box-sizing: border-box;
        width: calc(88/390*100vw);
        height: calc(33.33/390*100vw);
    }

    .loading .loading_text_name {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(24/390*100vw);
    }

    .loading .loading_text_line2 {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(15.14/390*100vw);
        margin-top: calc(5/390*100vw);
    }

    .loading .loading_img_line {
        display: block;
        margin-top: calc(3/390*100vw);
        width: calc(88/390*100vw);
        height: calc(1.3/390*100vw);
    }

    .loading .loading_img_capsule {
        display: block;
        width: calc(14.77/390*100vw);
        height: calc(15.42/390*100vw);
        margin-bottom: calc(60.91/390*100vw);
    }

    .question header .question_back {
        padding-top: calc(26.67/390*100vw);
        padding-left: calc(39/390*100vw);
        margin-bottom: calc(120.67/390*100vw);
    }

    .question header .question_back .question_back_btn {
        display: inline-block;
        width: calc(76.33/390*100vw);
        height: calc(20/390*100vw);
    }

    .question header .question_back .question_img_back_en {
        display:inline-block;
        font-family: 'Pretendard', sans-serif;
        font-weight: 500;
        font-size: calc(12/390*100vw);
        line-height: calc(1.144/390*100vw);
        margin-left: calc(38.6/390*100vw);
        margin-top: calc(1/390*100vw);
        margin-right: calc(6.2/390*100vw);
    }

    .question header .question_back .question_img_back_ko {
        display:inline-block;
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(12/390*100vw);
    }

    .question main .question_text_question {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(30/390*100vw);
        text-align: center;
        margin-bottom: calc(52/390*100vw);
        line-height: 1.2;
    }

    .question main .question_radio .question_content {
        height: calc(22/390*100vw);
        margin-bottom: calc(7.76/390*100vw);
        box-sizing: border-box;
    }

    .question main .question_radio .question_content_text {
        font-family: 'Pretendard', sans-serif;
        font-weight: 400;
        font-size: calc(18.33/390*100vw);
        margin-left: calc(10/390*100vw);
    }

    .question main .question_radio .question_img_checked {
        width: calc(29.33/390*100vw);
        height: calc(18/390*100vw);
    }

    .question_img_line {
        height: calc(1.3/390*100vw);
        margin-bottom: calc(51/390*100vw);
    }

    .question_img_line4 {
        margin-bottom: calc(103.7/390*100vw);
    }

    .question1 {
        width: 100vw;
        height: auto;
        background: url(../img/question/1/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .question1 .question_img_line {
        display: block;
        height: calc(1.3/390*100vw);
        margin-bottom: calc(51/390*100vw);
        width: calc(167.67/390*100vw);
    }

    .question2 {
        width: 100vw;
        height: auto;
        background: url(../img/question/2/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .question2 .question_img_line {
        display: block;
        height: calc(1.3/390*100vw);
        margin-bottom: calc(51/390*100vw);
        width: calc(285.67/390*100vw);
    }

    .question3 {
        width: 100vw;
        height: auto;
        background: url(../img/question/3/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .question3 .question_img_line {
        display: block;
        height: calc(1.3/390*100vw);
        margin-bottom: calc(51/390*100vw);
        width: calc(157/390*100vw);
    }

    .question4 {
        width: 100vw;
        height: auto;
        background: url(../img/question/4/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .question4 .question_img_line {
        display: block;
        height: calc(1.3/390*100vw);
        margin-bottom: calc(51/390*100vw);
        width: calc(220/390*100vw);
    }

    .question5 {
        width: 100vw;
        height: auto;
        background: url(../img/question/5/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .question5 .question_img_line {
        display: block;
        height: calc(1.3/390*100vw);
        margin-bottom: calc(51/390*100vw);
        width: calc(251/390*100vw);
    }

    .question6 {
        width: 100vw;
        height: auto;
        background: url(../img/question/6/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .question6 .question_img_line {
        display: block;
        height: calc(1.3/390*100vw);
        margin-bottom: calc(51/390*100vw);
        width: calc(167/390*100vw);
    }

    .question7 {
        width: 100vw;
        height: auto;
        background: url(../img/question/7/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .question7 .question_img_line {
        display: block;
        height: calc(1.3/390*100vw);
        margin-bottom: calc(51/390*100vw);
        width: calc(203/390*100vw);
    }

    .question8 {
        width: 100vw;
        height: auto;
        background: url(../img/question/8/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .question8 .question_img_line {
        display: block;
        height: calc(1.3/390*100vw);
        margin-bottom: calc(51/390*100vw);
        width: calc(211/390*100vw);
    }

    .analysis {
        width: 100vw;
        height: auto;
        background: url(../img/analysis/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .analysis .analysis_img_haus {
        position: absolute;
        width: calc(74.33/390*100vw);
        margin-top: calc(93.67/390*100vw);
        z-index: 1;
    }

    .analysis .analysis_text_line1 {
        margin-top: calc(215/390*100vw);
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(26.67/390*100vw);
    }

    .analysis .analysis_line2 {
        box-sizing: border-box;
        height: calc(18.33/390*100vw);
        margin-top: calc(13.1/390*100vw);
        margin-bottom: calc(64.78/390*100vw);
    }

    .analysis .analysis_name_box {
        box-sizing: border-box;
        width: calc(88/390*100vw);
        height: calc(33.33/390*100vw);
    }

    .analysis .analysis_text_name {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(24/390*100vw);
    }

    .analysis .analysis_text_line2 {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(15.14/390*100vw);
        margin-top: calc(5/390*100vw);
    }

    .analysis .analysis_img_line {
        display: block;
        margin-top: calc(3/390*100vw);
        width: calc(88/390*100vw);
        height: calc(1.3/390*100vw);
    }

    .analysis .analysis_img_capsule {
        display: block;
        width: calc(14.77/390*100vw);
        height: calc(15.42/390*100vw);
        margin-bottom: calc(60.91/390*100vw);
    }

    .result .result_text_box {
        height: calc(36/390*100vw);
        box-sizing: border-box;
        margin-top: calc(193.33/390*100vw);
    }

    .result .result_text_box .result_name_box .result_text_name {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: 6.33vw;
        letter-spacing: -0.25vw;
        margin-bottom: 1.41vw;
    }

    .result .result_text_box .result_name_box .result_img_line {
        display: block;
        width: 23.32vw;
        height: 0.29vw;
    }

    .result .result_text_box .result_text_line {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: 4.52vw;
        letter-spacing: -0.10vw;
        margin-top: 1.13vw;
    }

    .result .home_btn {
        position: fixed;
        bottom: 0;
        width: calc(100/390*100vw);
        height: calc(20/390*100vw);
    }
    .result .home_btn a {
        width: calc(100/390*100vw);
        height: calc(100/390*100vw);
    }

    .awb {
        width: 100vw;
        height: calc(1165.33/390*100vw);
        background: url(../img/result/awb.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .baw {
        width: 100vw;
        height: calc(1129.33/390*100vw);
        background: url(../img/result/baw.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .cac {
        width: 100vw;        
        height: calc(1105.67/390*100vw);
        background: url(../img/result/cac.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .cia {
        width: 100vw;
        height: calc(1187.33/390*100vw);
        background: url(../img/result/cia.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .dal {
        width: 100vw;
        height: calc(1187.33/390*100vw);
        background: url(../img/result/dal.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .daa {
        width: 100vw;
        height: calc(1105.33/390*100vw);
        background: url(../img/result/daa.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .ipa {
        width: 100vw;
        height: calc(1127.33/390*100vw);
        background: url(../img/result/ipa.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .paa {
        width: 100vw;
        height: calc(1187.33/390*100vw);
        background: url(../img/result/paa.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .pil {
        width: 100vw;
        height: calc(1105.67/390*100vw);
        background: url(../img/result/pil.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .rea {
        width: 100vw;
        height: calc(1127.33/390*100vw);
        background: url(../img/result/rea.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .sei {
        width: 100vw;
        height: calc(1164.667/390*100vw);
        background: url(../img/result/sei.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .sol {
        width: 100vw;
        height: calc(1127.33/390*100vw);
        background: url(../img/result/sol.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .spi {
        width: 100vw;
        height: calc(1072/390*100vw);
        background: url(../img/result/spi.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .sto {
        width: 100vw;
        height: calc(1105.33/390*100vw);
        background: url(../img/result/sto.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .sua {
        width: 100vw;
        height: calc(1164.667/390*100vw);
        background: url(../img/result/sua.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .whe {
        width: 100vw;
        height: calc(1072/390*100vw);
        background: url(../img/result/whe.png) no-repeat center center/cover;
        box-sizing: border-box;
    }
}

/* 한 화면에 들어오는 걸로 (vh로 단위 계산)... */
@media screen and (max-width:480px) {
    .qr {
        width: 100vw;
        height: 100vh;
        background-color: black;
        box-sizing: border-box;
    }

    .qr .card_area {
        position: absolute;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .qr .card_area .card_template {
        position: absolute;
        display: block;
        width: 100%;
    }
    
    .first {
        width: 100vw;
        height: calc(var(--ih, 100vh));
        background: url(../img/main/bg1.png) no-repeat center center/cover;
        box-sizing: border-box;
    }
    
    header .header_text_logo {
        width: calc(253/670*var(--ih, 100vh));
        height: calc(100/670*var(--ih, 100vh));
        margin-top: calc(57/670*var(--ih, 100vh));
    }

    .first .img_haus {
        width: calc(233/670*var(--ih, 100vh));
        height: calc(243/670*var(--ih, 100vh));
        margin-top: calc(68/670*var(--ih, 100vh));
        margin-bottom: calc(99/670*var(--ih, 100vh));
    }

    .first .first_button {
        width: calc(163/670*var(--ih, 100vh));
    }

    .start {
        height: calc(var(--ih, 100vh));
        background: url(../img/main/bg2.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .start .img_description {
        width: calc(200/670*var(--ih, 100vh));
        margin-top: calc(53.3/670*var(--ih, 100vh));
        margin-left: calc(60.3/670*var(--ih, 100vh));
        margin-bottom: calc(260.3/670*var(--ih, 100vh));
    }

    .start .start_button {
        width: calc(264/670*var(--ih, 100vh));
    }

    .age {
        height: calc(var(--ih, 100vh));
        background: url(../img/age/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .age .age_img_haus {
        position: absolute;
        width: calc(281/670*var(--ih, 100vh));
        margin-top: calc(140/670*var(--ih, 100vh));
        z-index: 1;
    }

    .age .age_text_question {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(17.5/670*var(--ih, 100vh));
        line-height: calc(21/670*var(--ih, 100vh));
        margin-top: calc(281/670*var(--ih, 100vh));
        margin-bottom: calc(31/670*var(--ih, 100vh));
        z-index: 2;
    }

    .age .radio_content .radio_box {
        display: inline-block;
        font-family: 'Pretendard', sans-serif;
        font-weight: 700;
        font-size: calc(17.46/670*var(--ih, 100vh));
        line-height: auto;
        z-index: 2;
        margin-bottom: calc(213/670*var(--ih, 100vh));
    }

    .age .radio_content .radio_box .age_img_check_box_yes {
        display: inline-block;
        width: calc(15/670*var(--ih, 100vh));
        height: calc(15/670*var(--ih, 100vh));
        margin-bottom: calc(-2/670*var(--ih, 100vh));
    }

    .age .radio_content .radio_box .age_img_checked_yes {
        display: inline-block;
        width: calc(10/670*var(--ih, 100vh));
        height: calc(8/670*var(--ih, 100vh));
        margin-bottom: calc(1/670*var(--ih, 100vh));
        margin-left: calc(-12.5/670*var(--ih, 100vh));
        margin-right: calc(13/670*var(--ih, 100vh));
    }

    .age .radio_content .radio_box .age_img_check_box_no {
        display: inline-block;
        width: calc(15/670*var(--ih, 100vh));
        height: calc(15/670*var(--ih, 100vh));
        margin-left: calc(28/670*var(--ih, 100vh));
        margin-bottom: calc(-2/670*var(--ih, 100vh));
    }

    .age .radio_content .radio_box .age_img_checked_no {
        display: inline-block;
        width: calc(10/670*var(--ih, 100vh));
        height: calc(8/670*var(--ih, 100vh));
        margin-bottom: calc(1/670*var(--ih, 100vh));
        margin-left: calc(-12.5/670*var(--ih, 100vh));
        margin-right: calc(13/670*var(--ih, 100vh));
    }

    .age .radio_content .radio_box .age_img_line {
        display:block;
        width: calc(70.74/670*var(--ih, 100vh));
        height: calc(1.14/670*var(--ih, 100vh));
        margin-right: calc(24/670*var(--ih, 100vh));
        margin-bottom: calc(216.4/670*var(--ih, 100vh));
    }

    .age .age_img_warning {
        width: calc(223/670*var(--ih, 100vh));
    }
    
    .info {
        height: calc(var(--ih, 100vh));
        background: url(../img/info/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .info .name_box {
        box-sizing: border-box;
        height: calc(110/670*var(--ih, 100vh));
        padding-top: calc(85/670*var(--ih, 100vh));
    }

    .info .name_box .label_name {        
        box-sizing: border-box;
        height: calc(18/670*var(--ih, 100vh));
        margin-top: calc(5.6/670*var(--ih, 100vh));
    }

    .info .name_box .label_name_ko {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(14/670*var(--ih, 100vh));
        letter-spacing: 0px;
        margin-right: calc(4.3/670*var(--ih, 100vh));
    }

    .info .name_box .label_name_en {
        font-family: 'Pretendard', sans-serif;
        font-weight: 500;
        font-size: calc(14/670*var(--ih, 100vh));
        margin-right: calc(33/670*var(--ih, 100vh));
    }

    .info .name_box .input_name {
        display: block;
        font-family: 'Pretendard', sans-serif;
        font-weight: 400;
        font-size: calc(16.7/670*var(--ih, 100vh));
        width: calc(207/670*var(--ih, 100vh));
        text-align: center;
        border: 0;
        margin: 0 0 calc(5/670*var(--ih, 100vh)) 0;
        padding: 0;
        background:transparent;
        outline: none;
    }

    .info .name_box .info_img_name_line {
        display: block;
        width: calc(207/670*var(--ih, 100vh));
        height: calc(1.3/670*var(--ih, 100vh));
    }
    
    .info .mobile_box {    
        box-sizing: border-box;    
        height: calc(38/670*var(--ih, 100vh));
        margin-top: calc(37/670*var(--ih, 100vh));
    }

    .info .mobile_box .label_mobile {    
        box-sizing: border-box;    
        margin-top: calc(6.3/670*var(--ih, 100vh));
    }

    .info .mobile_box .label_mobile_ko {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(14/670*var(--ih, 100vh));
        margin-right: calc(5.3/670*var(--ih, 100vh));
    }

    .info .mobile_box .label_mobile_en {
        font-family: 'Pretendard', sans-serif;
        font-weight: 500;
        font-size: calc(14/670*var(--ih, 100vh));
        margin-right: calc(9.3/670*var(--ih, 100vh));
    }

    .info .mobile_box .input_mobile {
        display: block;
        font-family: 'Pretendard', sans-serif;
        font-weight: 400;
        font-size: calc(16.7/670*var(--ih, 100vh));
        letter-spacing: calc(0.33/670*var(--ih, 100vh));
        width: calc(207/670*var(--ih, 100vh));
        text-align: center;
        border: 0;
        margin: 0 0 calc(4/670*var(--ih, 100vh)) 0;
        padding: 0;
        background:transparent;
        outline: none;
    }

    .info .mobile_box .info_img_mobile_line {
        display: block;
        width: calc(207/670*var(--ih, 100vh));
        height: calc(1.3/670*var(--ih, 100vh));
    }

    .info .mobile_box .info_img_mobile_notice_text {
        width: calc(200.3/670*var(--ih, 100vh));
        margin-top: calc(1/670*var(--ih, 100vh));
    }

    .info .gender_box {
        box-sizing: border-box;
        height: calc(16.7/670*var(--ih, 100vh));
        margin-top: calc(21.42/670*var(--ih, 100vh));
    }

    .info .gender_box .label_gender {
        box-sizing: border-box;
        height: calc(18/670*var(--ih, 100vh));
        margin-top: calc(1.1/670*var(--ih, 100vh));
    }

    .info .gender_box .label_gender_ko {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(14/670*var(--ih, 100vh));
        margin-right:calc(4.3/670*var(--ih, 100vh));
    }

    .info .gender_box .label_gender_en {
        font-family: 'Pretendard', sans-serif;
        font-weight: 500;
        font-size: calc(14/670*var(--ih, 100vh));
        margin-right: calc(18.3/670*var(--ih, 100vh));
    }

    .info .gender_box .gender_radio .info_img_check_box_male {
        display: inline-block;
        width: calc(15/670*var(--ih, 100vh));
        height: calc(15/670*var(--ih, 100vh));
        margin-bottom: calc(-3/670*var(--ih, 100vh));
    }

    .info .gender_box .gender_radio .info_img_checked_male {
        display: inline-block;
        width: calc(10/670*var(--ih, 100vh));
        height: calc(8/670*var(--ih, 100vh));
        margin-left: calc(-12.5/670*var(--ih, 100vh));
        margin-right: calc(7.3/670*var(--ih, 100vh));
    }

    .info .gender_box .gender_radio .info_text_male_ko {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(14/670*var(--ih, 100vh));
        margin-right: calc(5.3/670*var(--ih, 100vh));
    }

    .info .gender_box .gender_radio .info_text_male_en {
        font-family: 'Pretendard', sans-serif;
        font-weight: 500;
        font-size: calc(14/670*var(--ih, 100vh));
        margin-right: calc(44/670*var(--ih, 100vh));
    }

    .info .gender_box .gender_radio .info_img_check_box_female {
        display: inline-block;
        width: calc(15/670*var(--ih, 100vh));
        height: calc(15/670*var(--ih, 100vh));
        margin-bottom: calc(-3/670*var(--ih, 100vh));
    }

    .info .gender_box .info_img_male_line {
        display: block;
        width: calc(86.64/670*var(--ih, 100vh));
        height: calc(1.14/670*var(--ih, 100vh));
        margin-top: calc(2.8/670*var(--ih, 100vh));
        margin-right: calc(33.8/670*var(--ih, 100vh));
    }

    .info .gender_box .gender_radio .info_img_checked_female {
        display: inline-block;
        width: calc(10/670*var(--ih, 100vh));
        height: calc(8/670*var(--ih, 100vh));
        margin-left: calc(-12.5/670*var(--ih, 100vh));
        margin-right: calc(7.3/670*var(--ih, 100vh));
    }

    .info .gender_box .gender_radio .info_text_female_ko {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(14/670*var(--ih, 100vh));
        margin-right: calc(5.3/670*var(--ih, 100vh));
    }

    .info .gender_box .gender_radio .info_text_female_en {
        font-family: 'Pretendard', sans-serif;
        font-weight: 500;
        font-size: calc(14/670*var(--ih, 100vh));
    }

    .info .age_box {
        box-sizing: border-box;
        height: calc(16.7/670*var(--ih, 100vh));
        margin-top: calc(44.6/670*var(--ih, 100vh));
        margin-bottom: calc(52.2/670*var(--ih, 100vh));
    }

    .info .label_age {
        box-sizing: border-box;
        height: calc(16.7/670*var(--ih, 100vh));
    }

    .info .age_box .label_age_ko {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(14/670*var(--ih, 100vh));
        margin-right: calc(5.3/670*var(--ih, 100vh));
    }

    .info .age_box .label_age_en {
        font-family: 'Pretendard', sans-serif;
        font-weight: 500;
        font-size: calc(14/670*var(--ih, 100vh));
        margin-right: calc(32.7/670*var(--ih, 100vh));
    }

    .info .age_box .info_img_check_box_age {
        display: inline-block;
        width: calc(15/670*var(--ih, 100vh));
        height: calc(15/670*var(--ih, 100vh));
        margin-bottom: calc(-2/670*var(--ih, 100vh));
    }

    .info .age_box .age_radio {
        box-sizing: border-box;
        height: calc(16.7/670*var(--ih, 100vh));
        margin-right: calc(7.6/670*var(--ih, 100vh));
    }

    .info .age_box .info_img_checked_20, 
    .info .age_box .info_img_checked_30, 
    .info .age_box .info_img_checked_40, 
    .info .age_box .info_img_checked_50, 
    .info .age_box .info_img_checked_60 {
        display: inline-block;
        width: calc(10/670*var(--ih, 100vh));
        height: calc(8/670*var(--ih, 100vh));
        margin-left: calc(-12.5/670*var(--ih, 100vh));
        margin-right: calc(6/670*var(--ih, 100vh));
        margin-bottom: calc(1/670*var(--ih, 100vh));
    }

    .info .age_box .info_text_age {
        vertical-align: top;
        font-family: 'Pretendard', sans-serif;
        font-weight: 500;
        font-size: calc(14/670*var(--ih, 100vh));
        line-height: auto;
        letter-spacing: calc(0.33/670*var(--ih, 100vh));
    }

    .info .agree_box {
        box-sizing: border-box;
        vertical-align: top;
        height: calc(15/670*var(--ih, 100vh));
        margin-bottom: calc(10.6/670*var(--ih, 100vh));
    }

    .info .agree_box .label_agree {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(12/670*var(--ih, 100vh));
        margin-right: calc(8/670*var(--ih, 100vh));
    }

    .info .agree_box .agree_checkbox_box {
        margin-right: calc(33/670*var(--ih, 100vh));
    }

    .info .agree_box .info_img_agree_checkbox {
        display: inline-block;
        width: calc(15/670*var(--ih, 100vh));
        height: calc(15/670*var(--ih, 100vh));
        margin-bottom: calc(-4/670*var(--ih, 100vh));
    }

    .info .agree_box .info_img_agree_checked {
        display: inline-block;
        width: calc(10/670*var(--ih, 100vh));
        height: calc(8/670*var(--ih, 100vh));
        margin-left: calc(-12.5/670*var(--ih, 100vh));
        margin-bottom: calc(-1/670*var(--ih, 100vh));
    }
    
    .info .agree_box .label_agree_see {
        font-family: 'Pretendard', sans-serif;
        font-weight: 500;
        font-size: calc(10.5/670*var(--ih, 100vh));
        margin-bottom: calc(1.4/670*var(--ih, 100vh));
    }

    .info .warning_box .warning_text .info_img_warning_text {
        width: calc(305.9/670*var(--ih, 100vh));
        height: calc(9.76/670*var(--ih, 100vh));
    }

    .info .info_img_register {
        width: calc(263.7/670*var(--ih, 100vh));
        height: calc(53.8/670*var(--ih, 100vh));
        margin-top: calc(197.6/670*var(--ih, 100vh));
        margin-bottom: calc(49.2/670*var(--ih, 100vh));
    }

    .info_img_popup {
        position: absolute;
        top: calc(70/390*100vw);
        width: calc(321.6/390*100vw);
        height: calc(530/390*100vw);
        z-index: 3;
    }
    
    .info_img_popup_close {
        position: absolute;
        top: calc(553/390*100vw);
        width: calc(77.3/390*100vw);
        height: calc(20.3/390*100vw);
        z-index: 4;
    }

    .info_img_popup_privacy_use_agree_checked {
        position: absolute;
        top: calc(115.4/390*100vw);
        margin-left: calc(161.22/390*100vw);
        width: calc(5.2/390*100vw);
        height: calc(3.8/390*100vw);
        z-index: 4;
    }

    .info_img_popup_privacy_use_agree {
        position: absolute;
        top: calc(113.4/390*100vw);
        margin-left: calc(159.6/390*100vw);
        width: calc(97/390*100vw);
        height: calc(8.33/390*100vw);
        z-index: 4;
    }

    .info_img_popup_privacy_use_disagree_checked {
        position: absolute;
        top: calc(115.4/390*100vw);
        margin-left: calc(281/390*100vw);
        width: calc(5.2/390*100vw);
        height: calc(3.8/390*100vw);
        z-index: 4;
    }

    .info_img_popup_privacy_use_disagree {
        position: absolute;
        top: calc(113.4/390*100vw);
        margin-left: calc(279.4/390*100vw);
        width: calc(48.39/390*100vw);
        height: calc(8.33/390*100vw);
        z-index: 4;
    }

    .info_img_popup_marketing_agree_checked {
        position: absolute;
        top: calc(265/390*100vw);
        margin-left: calc(160.7/390*100vw);
        width: calc(5.2/390*100vw);
        height: calc(3.8/390*100vw);
        z-index: 4;
    }

    .info_img_popup_marketing_agree {
        position: absolute;
        top: calc(263/390*100vw);
        margin-left: calc(159.6/390*100vw);
        width: calc(95.67/390*100vw);
        height: calc(8.33/390*100vw);
        z-index: 4;
    }

    .info_img_popup_marketing_disagree_checked {
        position: absolute;
        top: calc(265/390*100vw);
        margin-left: calc(277.5/390*100vw);
        width: calc(5.2/390*100vw);
        height: calc(3.8/390*100vw);
        z-index: 4;
    }

    .info_img_popup_marketing_disagree {
        position: absolute;
        top: calc(263/390*100vw);
        margin-left: calc(276/390*100vw);
        width: calc(48.39/390*100vw);
        height: calc(8.33/390*100vw);
        z-index: 4;
    }

    .info_img_popup_privacy_offer_agree_checked {
        position: absolute;
        top: calc(358/390*100vw);
        margin-left: calc(159/390*100vw);
        width: calc(7.95/390*100vw);
        height: calc(8.56/390*100vw);
        z-index: 4;
    }

    .info_img_popup_privacy_offer_agree_checked {
        position: absolute;
        top: calc(383/390*100vw);
        margin-left: calc(161.1/390*100vw);
        width: calc(5.2/390*100vw);
        height: calc(3.8/390*100vw);
        z-index: 4;
    }

    .info_img_popup_privacy_offer_agree {
        position: absolute;
        top: calc(381.2/390*100vw);
        margin-left: calc(159.6/390*100vw);
        width: calc(97/390*100vw);
        height: calc(8.33/390*100vw);
        z-index: 4;
    }

    .info_img_popup_privacy_offer_disagree {
        position: absolute;
        top: calc(361/390*100vw);
        margin-left: calc(276/390*100vw);
        width: calc(49.7/390*100vw);
        height: calc(7.96/390*100vw);
        z-index: 4;
    }

    .info_img_popup_privacy_offer_disagree_checked {
        position: absolute;
        top: calc(383/390*100vw);
        margin-left: calc(277.6/390*100vw);
        width: calc(5.2/390*100vw);
        height: calc(3.8/390*100vw);
        z-index: 4;
    }

    .info_img_popup_privacy_offer_disagree {
        position: absolute;
        top: calc(381.2/390*100vw);
        margin-left: calc(276/390*100vw);
        width: calc(48.39/390*100vw);
        height: calc(8.33/390*100vw);
        z-index: 4;
    }

    .info_img_popup_privacy_policy_link {
        position: absolute;
        top: calc(495.33/390*100vw);
        margin-left: calc(60.67/390*100vw);
        width: calc(85.33/390*100vw);
        height: calc(9/390*100vw);
        z-index: 4;
    }

    .loading {
        height: calc(var(--ih, 100vh));
        background: url(../img/loading/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .loading .loading_img_haus {
        position: absolute;
        width: calc(74.33/390*100vw);
        margin-top: calc(93.67/390*100vw);
        z-index: 1;
    }

    .loading .loading_text_line1 {
        margin-top: calc(215/670*var(--ih, 100vh));
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(26.67/670*var(--ih, 100vh));
    }

    .loading .loading_line2 {
        box-sizing: border-box;
        height: calc(18.33/670*var(--ih, 100vh));
        margin-top: calc(13.1/670*var(--ih, 100vh));
        margin-bottom: calc(64.78/670*var(--ih, 100vh));
    }

    .loading .loading_name_box {
        box-sizing: border-box;
        width: calc(88/670*var(--ih, 100vh));
        height: calc(33.33vw/670*var(--ih, 100vh));
    }

    .loading .loading_text_name {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(24/670*var(--ih, 100vh));
    }

    .loading .loading_text_line2 {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(15.14/670*var(--ih, 100vh));
        margin-top: calc(5/670*var(--ih, 100vh));
    }

    .loading .loading_img_line {
        display: block;
        margin-top: calc(3/670*var(--ih, 100vh));
        width: calc(88/670*var(--ih, 100vh));
        height: calc(1.3/670*var(--ih, 100vh));
    }

    .loading .loading_img_capsule {
        display: block;
        width: calc(14.77/670*var(--ih, 100vh));
        height: calc(15.42/670*var(--ih, 100vh));
        margin-bottom: calc(60.91/670*var(--ih, 100vh));
    }

    .question header .question_back {
        padding-top: calc(26.67/670*var(--ih, 100vh));
        padding-left: calc(39/670*var(--ih, 100vh));
        margin-bottom: calc(120.67/670*var(--ih, 100vh));
    }

    .question header .question_back .question_back_btn {
        display: inline-block;
        width: calc(76.33/670*var(--ih, 100vh));
        height: calc(20/670*var(--ih, 100vh));
    }

    .question header .question_back .question_img_back_en {
        display:inline-block;
        font-family: 'Pretendard', sans-serif;
        font-weight: 500;
        font-size: calc(12/670*var(--ih, 100vh));
        letter-spacing: calc(-0.48/670*var(--ih, 100vh));
        line-height: calc(1.144/670*var(--ih, 100vh));
        margin-left: calc(38.6/670*var(--ih, 100vh));
        margin-top: calc(1/670*var(--ih, 100vh));
        margin-right: calc(6.2/670*var(--ih, 100vh));
    }

    .question header .question_back .question_img_back_ko {
        display:inline-block;
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(12/670*var(--ih, 100vh));
        letter-spacing: calc(-0.42/670*var(--ih, 100vh));
    }

    .question main .question_text_question {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(30/670*var(--ih, 100vh));
        text-align: center;
        margin-bottom: calc(52/670*var(--ih, 100vh));
        line-height: 1.2;
    }

    .question main .question_radio .question_content {
        height: calc(22/670*var(--ih, 100vh));
        margin-bottom: calc(7.76/670*var(--ih, 100vh));
        box-sizing: border-box;
    }

    .question main .question_radio .question_content_text {
        font-family: 'Pretendard', sans-serif;
        font-weight: 400;
        font-size: calc(18.33/670*var(--ih, 100vh));
        margin-left: calc(10/670*var(--ih, 100vh));
    }

    .question main .question_radio .question_img_checked {
        width: calc(29.33/670*var(--ih, 100vh));
        height: calc(18/670*var(--ih, 100vh));
    }

    .question_img_line {
        height: calc(1.3/670*var(--ih, 100vh));
        margin-bottom: calc(51/670*var(--ih, 100vh));
    }

    .question1 {
        height: calc(var(--ih, 100vh));
        background: url(../img/question/1/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .question1 .question_img_line {
        display: block;
        height: calc(1.3/670*var(--ih, 100vh));
        margin-bottom: calc(51/670*var(--ih, 100vh));
        width: calc(167.67/670*var(--ih, 100vh));
    }

    .question2 {
        height: calc(var(--ih, 100vh));
        background: url(../img/question/2/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .question2 .question_img_line {
        display: block;
        height: calc(1.3/670*var(--ih, 100vh));
        margin-bottom: calc(51/670*var(--ih, 100vh));
        width: calc(285.67/670*var(--ih, 100vh));
    }

    .question3 {
        height: calc(var(--ih, 100vh));
        background: url(../img/question/3/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .question3 .question_img_line {
        display: block;
        height: calc(1.3/670*var(--ih, 100vh));
        margin-bottom: calc(51/670*var(--ih, 100vh));
        width: calc(157/670*var(--ih, 100vh));
    }

    .question4 {
        height: calc(var(--ih, 100vh));
        background: url(../img/question/4/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .question4 .question_img_line {
        display: block;
        height: calc(1.3/670*var(--ih, 100vh));
        margin-bottom: calc(51/670*var(--ih, 100vh));
        width: calc(220/670*var(--ih, 100vh));
    }

    .question5 {
        height: calc(var(--ih, 100vh));
        background: url(../img/question/5/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .question5 .question_img_line {
        display: block;
        height: calc(1.3/670*var(--ih, 100vh));
        margin-bottom: calc(51/670*var(--ih, 100vh));
        width: calc(251/670*var(--ih, 100vh));
    }

    .question6 {
        height: calc(var(--ih, 100vh));
        background: url(../img/question/6/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .question6 .question_img_line {
        display: block;
        height: calc(1.3/670*var(--ih, 100vh));
        margin-bottom: calc(51/670*var(--ih, 100vh));
        width: calc(167/670*var(--ih, 100vh));
    }

    .question7 {
        height: calc(var(--ih, 100vh));
        background: url(../img/question/7/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .question7 .question_img_line {
        display: block;
        height: calc(1.3/670*var(--ih, 100vh));
        margin-bottom: calc(51/670*var(--ih, 100vh));
        width: calc(203/670*var(--ih, 100vh));
    }

    .question8 {
        height: calc(var(--ih, 100vh));
        background: url(../img/question/8/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .question8 .question_img_line {
        display: block;
        height: calc(1.3/670*var(--ih, 100vh));
        margin-bottom: calc(51/670*var(--ih, 100vh));
        width: calc(211/670*var(--ih, 100vh));
    }

    .analysis {
        height: calc(var(--ih, 100vh));
        background: url(../img/analysis/bg.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .analysis .analysis_img_haus {
        position: absolute;
        width: calc(74.33/390*100vw);
        margin-top: calc(93.67/390*100vw);
        z-index: 1;
    }

    .analysis .analysis_text_line1 {
        margin-top: calc(215/670*var(--ih, 100vh));
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(26.67/670*var(--ih, 100vh));
    }

    .analysis .analysis_line2 {
        box-sizing: border-box;
        height: calc(18.33/670*var(--ih, 100vh));
        margin-top: calc(13.1/670*var(--ih, 100vh));
        margin-bottom: calc(64.78/670*var(--ih, 100vh));
    }

    .analysis .analysis_name_box {
        box-sizing: border-box;
        width: calc(88/670*var(--ih, 100vh));
        height: calc(33.33vw/670*var(--ih, 100vh));
    }

    .analysis .analysis_text_name {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(24/670*var(--ih, 100vh));
    }

    .analysis .analysis_text_line2 {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: calc(15.14/670*var(--ih, 100vh));
        margin-top: calc(5/670*var(--ih, 100vh));
    }

    .analysis .analysis_img_line {
        display: block;
        margin-top: calc(3/670*var(--ih, 100vh));
        width: calc(88/670*var(--ih, 100vh));
        height: calc(1.3/670*var(--ih, 100vh));
    }

    .analysis .analysis_img_capsule {
        display: block;
        width: calc(14.77/670*var(--ih, 100vh));
        height: calc(15.42/670*var(--ih, 100vh));
        margin-bottom: calc(60.91/670*var(--ih, 100vh));
    }

    .result .result_text_box {
        height: calc(36/390*100vw);
        box-sizing: border-box;
        margin-top: calc(193.33/390*100vw);
    }

    .result .result_text_box .result_name_box .result_text_name {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: 6.33vw;
        letter-spacing: -0.25vw;
        margin-bottom: 1.41vw;
    }

    .result .result_text_box .result_name_box .result_img_line {
        display: block;
        width: 23.32vw;
        height: 0.29vw;
    }

    .result .result_text_box .result_text_line {
        font-family: 'Pretendard', sans-serif;
        font-weight: 600;
        font-size: 4.52vw;
        letter-spacing: -0.10vw;
        margin-top: 1.13vw;
    }

    .result .home_btn {
        position: fixed;
        bottom: 0;
        width: calc(100/390*100vw);
        height: calc(20/390*100vw);
    }
    .result .home_btn a {
        width: calc(100/390*100vw);
        height: calc(100/390*100vw);
    }

    .awb {
        width: 100vw;
        height: calc(1165.33/390*100vw);
        background: url(../img/result/awb.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .baw {
        width: 100vw;
        height: calc(1129.33/390*100vw);
        background: url(../img/result/baw.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .cac {
        width: 100vw;
        height: calc(1105.67/390*100vw);
        background: url(../img/result/cac.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .cia {
        width: 100vw;
        height: calc(1187.33/390*100vw);
        background: url(../img/result/cia.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .dal {
        width: 100vw;
        height: calc(1187.33/390*100vw);
        background: url(../img/result/dal.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .daa {
        width: 100vw;
        height: calc(1105.33/390*100vw);
        background: url(../img/result/daa.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .ipa {
        width: 100vw;
        height: calc(1127.33/390*100vw);
        background: url(../img/result/ipa.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .paa {
        width: 100vw;
        height: calc(1187.33/390*100vw);
        background: url(../img/result/paa.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .pil {
        width: 100vw;
        height: calc(1105.67/390*100vw);
        background: url(../img/result/pil.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .rea {
        width: 100vw;
        height: calc(1127.33/390*100vw);
        background: url(../img/result/rea.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .sei {
        width: 100vw;
        height: calc(1164.667/390*100vw);
        background: url(../img/result/sei.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .sol {
        width: 100vw;
        height: calc(1127.33/390*100vw);
        background: url(../img/result/sol.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .spi {
        width: 100vw;
        height: calc(1072/390*100vw);
        background: url(../img/result/spi.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .sto {
        width: 100vw;
        height: calc(1105.33/390*100vw);
        background: url(../img/result/sto.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .sua {
        width: 100vw;
        height: calc(1164.667/390*100vw);
        background: url(../img/result/sua.png) no-repeat center center/cover;
        box-sizing: border-box;
    }

    .whe {
        width: 100vw;
        height: calc(1072/390*100vw);
        background: url(../img/result/whe.png) no-repeat center center/cover;
        box-sizing: border-box;
    }
}